import React from "react";
import "./spinner.css";
import { ClipLoader } from "react-spinners";


export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <ClipLoader color="#0375B0" />
      {/* <div className="loading-spinner"></div> */}
      
    </div>
  );
}