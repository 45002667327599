import React, { useEffect, useState } from 'react';
import { TextField } from "@mui/material";
import './Address.css';
import { useHistory } from 'react-router-dom';
import ApiService from '../app/utils/ApiService';

const AddressPage = (props) => {
    const address = props.location.state.address;
    const [name, setName] = useState('');
    const [block, setBlock] = useState('');
    const [area, setArea] = useState('');
    const [label, setLabel] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const history = useHistory();
    console.log("ADR", address)

    useEffect(() => {
        if (address.city) {
            setCity(address.city);
        } else if (address.pincode) {
            setPincode(address.pincode)
        }
    }, [])

    const saveAddress = async () => {
        const data = {
            userName: name.trim(),
            addressLine1: block.trim(),
            addressLine2: address.address.trim(),
            apartment_or_area: area.trim(),
            label: label,
            city: city,
            pincode: pincode,
            latitude: address.lat,
            longitude: address.lng,
            type: null
        }
        console.log("WhoeAddress==>", data)
        const token = localStorage.getItem('sessionId')
        await ApiService.updateUserAddress(data, token)
            .then(async response => {
                console.log("Result", response);
                if (response.data.status === 0) {
                    localStorage.setItem("addressUpdated", '1');
                    history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/cart` : '/cart')
                }
            })
        // RegisterLoginService.updateUserAddress(data, token).then(async result => {
        //   if (result.code === 0) {
        //     // console.log('manully address===>', result.data)
        //     dispatch(priorityaddressaction({ label: result.data.label, address: result.data.addressLine2, latitude: result.data.latitude, longitude: result.data.longitude }))
        //     if (chatAddress == '1') {
        //       console.log('chatAddress===>', chatAddress)
        //       navigation.pop(2)
        //     } else {
        //       navigation.popToTop()
        //     }
        //   } else if (result.message === 'Network Error') {
        //     Toast.show('Something went wrong,Please Try again later', Toast.LONG)
        //   }
        // }).catch((err) => console.log(err)).finally(() => { setIsProgress(false) })
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='address_container' style={{ padding: '10px' }}>
                <p className='bold_head'>You have selected:</p>
                <p className='formatted_address'>{address.address}</p>
                <p className='bold_head'>Please provide address</p>
                <TextField className='address_input' onChange={(e) => {
                    let input = e.target;
                    if (input.value.includes('  ')) {
                        input.value = input.value.replace(/\s+/g, ' ');
                    }
                    if (input.value.charAt(0) === ' ') {
                        input.value = input.value.substring(1);
                    }
                    setName(input.value)
                }} label={<p style={{ marginTop: 0, fontWeight: '300', fontSize: '13px' }}>NAME<span style={{ color: 'red', marginLeft: '5px' }}>*</span></p>} style={{ width: '100%', marginBottom: '20px' }} />
                <TextField className='address_input' onChange={(e) => {
                    let input = e.target;
                    if (input.value.includes('  ')) {
                        input.value = input.value.replace(/\s+/g, ' ');
                    }
                    if (input.value.charAt(0) === ' ') {
                        input.value = input.value.substring(1);
                    }
                    setBlock(input.value)
                }} type="text" label={<p style={{ marginTop: 0, fontWeight: '300', fontSize: '13px' }}>HOUSE / FLAT / BLOCK NO<span style={{ color: 'red', marginLeft: '5px' }}>*</span></p>} style={{ width: '100%', marginBottom: '20px' }} />
                <TextField className='address_input' onChange={(e) => {
                    let input = e.target;
                    if (input.value.includes('  ')) {
                        input.value = input.value.replace(/\s+/g, ' ');
                    }
                    if (input.value.charAt(0) === ' ') {
                        input.value = input.value.substring(1);
                    }
                    setArea(input.value)
                }} type="text" label={<p style={{ marginTop: 0, fontWeight: '300', fontSize: '13px' }}>Apartment / Area</p>} style={{ width: '100%', marginBottom: '20px' }} />
                <TextField className='address_input' label={<p style={{ marginTop: 0, fontWeight: '300', fontSize: '13px' }}>AREA / ROAD / SECTOR<span style={{ color: 'red', marginLeft: '5px' }}>*</span></p>} defaultValue={address.address} disabled={true} style={{ width: '100%', marginBottom: '20px' }} />
                <div>
                    <p className='bold_head'>Label your address<span style={{ color: 'red', marginLeft: '5px' }}>*</span></p>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <botton onClick={() => setLabel('Home')} className={label === 'Home' ? 'label_buttons_selected' : 'label_buttons'}>Home</botton>
                        <botton onClick={() => setLabel('Office')} className={label === 'Office' ? 'label_buttons_selected' : 'label_buttons'}>Office</botton>
                        <botton onClick={() => setLabel('Parents')} className={label === 'Parents' ? 'label_buttons_selected' : 'label_buttons'}>Parents</botton>
                        <botton onClick={() => setLabel('Friends')} className={label === 'Friends' ? 'label_buttons_selected' : 'label_buttons'}>Friends</botton>
                    </div>
                </div>
                <center>
                    <button className={(label !== '' && name !== '' && block !== '') ? 'proceed_btn' : 'proceed_btn_disabled'} disabled={(label !== '' && name !== '' && block !== '') ? false : true} onClick={saveAddress}>Proceed</button>
                </center>
            </div>


        </div>
    )
}

export default AddressPage
