import React from "react";
import "./index.scss";
import playstore from "../../../assets/images/play-store.png";
import business_page_cover from "../../../assets/business_page_cover.jpg";
import business_image1 from "../../../assets/business_image1.png";
import business_image2 from "../../../assets/business_image2.png";
import business_image3 from "../../../assets/business_image3.png";
import business_image4 from "../../../assets/business_image4.png";
import business_image5 from "../../../assets/business_image5.png";
import business_image6 from "../../../assets/business_image6.png";
import business_image7 from "../../../assets/business_image7.png";
import text_box_business from "../../../assets/text_box_business.png";
import ondcTag from "../../../assets/ondc-tag.png";
import download_on_appstore from "../../../assets/download_on_appstore.svg";
import chattybao_logo from "../../../assets/chattybao-logo.png";

const BusinessPage = () => {
  return (
    <>
    
      <div
        className="top_container margin_less"
        style={{ marginBottom: "80px" }}
      >
        <img
          className="business_cover_image"
          src={business_page_cover}
          style={{ width: "100%" }}
          alt="NA"
        />
        <div className="business-box-whole-container">
          <div className="business_box_container">
            <img className="big_box_business" src={text_box_business} alt="NA" />
            <div
              style={{
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="business_box_container_text">Have a business?</p>
              <p className="inside_box_text">
                Sign up in 5 minutes
                <br />
                <span className="bold-text">CB Partner</span>, your All in One Business App
              </p>
              <div className="playstore-appstore-container">
                <a
                  href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                >
                  <img className="playstore_icon" src={playstore} alt="playstore" />
                </a>
                <a href="https://apps.apple.com/in/app/cb-partner/id6449996109">
                  <img
                    className="whatsapp_icon_top"
                    src={download_on_appstore}
                    alt="playstore"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="ondc-tag-container">
            <img src={ondcTag} className="ondc-tag-image" alt="NA" />
          </div>
        </div>

      </div>
      <div className="body_container">
        <div
          className="business_ads_container"
          style={{ backgroundColor: "#def4ff" }}
        >
          <div className="business_ad_container">
            <img src={business_image1} className="business_ad_image" alt="NA" />
            <div className="text_align">
              <p className="card-topic-header">
                Losing Customers to Online?
              </p>
              <div className="card-topic-divider" />
              <p className="card-topic-subtext max_width">
                Grow your Business with
              </p>
              <img src={chattybao_logo} className="chattyboa-logo-inside-topic" alt="NA" />
            </div>
          </div>
        </div>
        <div className="business_ads_container">
          <div className="business_ad_container2">
            <div className="adtext_container">
              <p className="card-topic-header">
                E-commerce Jaise Home Delivery
              </p>
              <div className="card-topic-divider" />
              <ul className="listed-card-data">
                <li className="bullet-text max_width">For all your orders on whatsapp, call or online</li>
                <li className="bullet-text max_width">Delivery in 30-45 mins</li>
                <li className="bullet-text max_width">Live tracking for you & your customers</li>
              </ul>
            </div>
            <img className="business_ad_image2" src={business_image2} alt="NA" />
          </div>
        </div>
        <div
          className="business_ads_container"
          style={{ backgroundColor: "#def4ff" }}
        >
          <div className="business_ad_container">
            <img className="business_ad_image" src={business_image3} alt="NA" />
            <div className="adtext_container">
              <p className="card-topic-header">WhatsApp pe Visiting Card</p>
              <div className="card-topic-divider" />
              <ul className="listed-card-data">
                <li className="bullet-text">Customers losing your visiting card? Not anymore</li>
                <li className="bullet-text">Save costs on printing</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="business_ads_container">
          <div className="business_ad_container2">
            <div className="adtext_container">
              <p className="card-topic-header">Apni Website</p>
              <p className="adsub_text max_width">
                Get your own website, no expert help needed
              </p>
              <div className="card-topic-divider" />
              <ul className="listed-card-data">
                <li className="bullet-text max_width">Showcase your catalog</li>
                <li className="bullet-text max_width">Receive online orders</li>
                <li className="bullet-text max_width">Online payment collection & delivery</li>
              </ul>
            </div>
            <img className="business_ad_image2" src={business_image4} alt="NA" />
          </div>
        </div>
        <div
          className="business_ads_container"
          style={{ backgroundColor: "#def4ff" }}
        >
          <div className="business_ad_container">
            <img className="business_ad_image" src={business_image5} alt="NA" />
            <div className="adtext_container">
              <p className="card-topic-header">Build your Catalog</p>
              <p className="adsub_text max_width">
                Easily, with India's best catalog tools
              </p>
              <div className="card-topic-divider" />
              <ul className="listed-card-data">
                <li className="bullet-text max_width">Choose from 1 Lac+ listed products</li>
                <li className="bullet-text max_width">Import your existing catalog</li>
                <li className="bullet-text max_width">AI powered tools for superior images</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="business_ads_container">
          <div className="business_ad_container2">
            <div className="adtext_container">
              <p className="card-topic-header">One-click Marketing</p>
              <p className="adsub_text max_width">
                With easy to use marketing tools
              </p>
              <div className="card-topic-divider" />
              <ul className="listed-card-data">
                <li className="bullet-text max_width">All your customer data in one place</li>
                <li className="bullet-text max_width">Send promotions and offers to your & ChattyBao customers</li>
              </ul>
            </div>
            <img className="business_ad_image2" src={business_image6} alt="NA" />
          </div>
        </div>
        <div
          className="business_ads_container"
          style={{ backgroundColor: "#def4ff" }}
        >
          <div className="business_ad_container">
            <img className="business_ad_image" src={business_image7} alt="NA" />
            <div className="adtext_container">
              <p className="card-topic-header">Instant ONDC Listing</p>
              <p className="adsub_text max_width">
                ChattyBao is an ONDC Seller Partner
              </p>
              <div className="card-topic-divider" />
              <ul className="listed-card-data">
                <li className="bullet-text max_width">List your business instantly on popular apps like Paytm, Pincode etc</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="business_ads_container">
          <div>
            <p className="thirdcontainer_text">
              Dukaan ka Bharosa, Online ka Aaram!
            </p>
            <p
              className="download_text"
              style={{ textAlign: "center", marginLeft: "-20px" }}
            >
              Download App now
            </p>
            <div className="app-download-btns-container">
              <a href="https://play.google.com/store/apps/details?id=com.goping.merchant">
                <img className="playstore_icon" src={playstore} alt="playstore" />
              </a>
              <a href="https://chattybaouser.page.link/RLd2">
                <img
                  className="whatsapp_icon"
                  src={download_on_appstore}
                  alt="playstore"
                />
              </a>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default BusinessPage;
