import React, { useState, useEffect, useRef } from 'react';

const TextOverFlow = ({ text }) => {
    const { isOverflowing, containerRef } = useTextOverflow(text);
    const [moreText, setMoreText] = useState(false);

    return (
        <div ref={containerRef}>
            <p className={moreText ? 'maincards_typodescription-whole-text' : (isOverflowing ? 'maincards_typodescription2' : 'maincards_typodescription-whole-text')}>{text}</p>
            {isOverflowing && !moreText && <span onClick={() => setMoreText(true)} className='more-text'>... more</span>}
        </div>
    );
};

export default TextOverFlow;

const useTextOverflow = (text, maxLines = 2) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            console.log("Heights", container.clientHeight)
        //   const lineHeight = parseInt(window.getComputedStyle(container).lineHeight);
          setIsOverflowing(container.clientHeight > 30);
        }
      }, [text]);

    return { isOverflowing, containerRef };
};