import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Logo from "../../../assets/logo.png";
import Whatsapp from "../../../assets/whatsapp.png";
import "./Index.css";

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: "100%",
	[theme.breakpoints.up("sm")]: {
		marginLeft: theme.spacing(3),
		width: "auto",
	},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: "20ch",
		},
	},
}));

export default function Index() {
	const totalOrderData = useSelector((state) => state.counter.Order);
	const cartValue = useSelector((state) => state.counter.value);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const history = useHistory();

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const merchantdetails = JSON.parse(localStorage.getItem("merchantdetails"));

	const handlegohome = () => {
		history.push({
			pathname: "/",
		});
		window.location.reload();
	};
	const urls = window.location.href;
	const aliasname1 = urls.substring(urls.lastIndexOf("/") + 1);
	const alias = aliasname1.split("#");
	const aliasname2 = alias[0].split("?");
	var url = new URL(urls);
	var userApp = url.searchParams.get("isUserApp");
	const aliasname = aliasname2[0];
	const [isFruitsAndVegMerchant, setIsFruitsAndVegMerchant] = useState("");
	let l3 = merchantdetails && merchantdetails.data.categoryl3;

	useEffect(() => {
		if (
			"95f732e0-737b-11ec-9806-93163a488dc9" == l3 ||
			"3d795333-84ae-11ec-a0d0-795c4aba562b" == l3
		) {
			setIsFruitsAndVegMerchant("FRUITS");
		} else if ("8650b2d0-737b-11ec-9806-93163a488dc9" == l3) {
			setIsFruitsAndVegMerchant("GROCERY");
		} else {
			setIsFruitsAndVegMerchant("");
		}
	}, []);

	const handlesendoder = () => {
		window.open(totalOrderData);
	};
	const handlewhatsapp = () => {
		window.open(
			`https://api.whatsapp.com/send?phone=919289454472&text=Website order for ${aliasname}`
		);
	};

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={handleMenuClose}>Profile</MenuItem>
			<MenuItem onClick={handleMenuClose}>My account</MenuItem>
		</Menu>
	);

	const mobileMenuId = "primary-search-account-menu-mobile";
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<IconButton
					size="large"
					aria-label="show 17 new notifications"
					color="inherit"
				>
					<Badge badgeContent={17} color="error">
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<p>Notifications</p>
			</MenuItem>
			<MenuItem onClick={handleProfileMenuOpen}>
				<IconButton
					size="large"
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<p>Profile</p>
			</MenuItem>
		</Menu>
	);
	console.log("sendorderUrl---->", totalOrderData);
	console.log("cartValue---->", cartValue);

	return (
		<>
			{/* boxmobile */}
			<Box sx={{ flexGrow: 1 }} className="boxmobile">
				<span>
					<img src={Logo} alt="NA" width={100} height={40} />
				</span>

				<span>
					{cartValue === true ? (
						<button className="button_boxwhatapp" onClick={handlesendoder}>
							<span style={{ color: "white", padding: "1px 10px 0px 0px" }}>
							Click to chat
							</span>
							<span>
								<img src={Whatsapp} alt="NA" width={20} height={21} />
							</span>
						</button>
					) : (
						<button className="button_boxwhatapp" onClick={handlewhatsapp}>
							<span style={{ color: "white", padding: "1px 10px 0px 0px" }}>
							Click to chat
							</span>
							<span>
								<img src={Whatsapp} alt="NA" width={20} height={21} />
							</span>
						</button>
					)}
				</span>
			</Box>

			{/* boxwebsite */}
			<Box sx={{ flexGrow: 1 }} className="boxwebsite">
				<AppBar position="fixed">
					<Toolbar>
						<Typography
							variant="h6"
							noWrap
							component="div"
							sx={{ display: { xs: "none", sm: "block" } }}
						>
							ChattyBao
						</Typography>
						{/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} />
            </Search> */}
						<Box sx={{ flexGrow: 1 }} />
						<Box sx={{ display: { xs: "none", md: "flex" } }}>
							{/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
      <Badge badgeContent={4} color="error">
        <MailIcon />
      </Badge>
    </IconButton> */}
							{/* <IconButton size="large" color="inherit" aria-label="products">
                <Badge badgeContent={Totalcart ? Totalcart.length : "0"} color="error">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton> */}
							<IconButton
								size="large"
								edge="end"
								aria-label="account of current user"
								aria-controls={menuId}
								aria-haspopup="true"
								onClick={handleProfileMenuOpen}
								color="inherit"
							>
								<AccountCircle />
							</IconButton>
						</Box>
						<Box sx={{ display: { xs: "flex", md: "none" } }}>
							<IconButton
								size="large"
								aria-label="show more"
								aria-controls={mobileMenuId}
								aria-haspopup="true"
								onClick={handleMobileMenuOpen}
								color="inherit"
							>
								<MoreIcon />
							</IconButton>
						</Box>
					</Toolbar>
				</AppBar>
				{renderMobileMenu}
				{renderMenu}
			</Box>
		</>
	);
}
