import React from "react";
import PropTypes from "prop-types";
// import MainSidebar from "../pages/sidebar";
import { Row, Col } from "react-bootstrap";
import { Container } from "reactstrap";
import Navbar from "../components/navbar/Index";
// import "./index.css";

const DefaultLayout = ({ children, noNavbar, noFooter }) => (
  <div>
    {/* <Row> */}
    {/* <Container fluid>
      <Row> */}
    {/* <Col xs={2}><MainSidebar /></Col> */}
    {/* <Col
        className="main-content p-0"
        lg={{ size: 10, offset: 2 }}
        md={{ size: 9, offset: 3 }}
        sm="12"
        tag="main"
      > */}

    {/* <div className="scrollbar" id="style-15">
          <div className="force-overflow"> */}
    <div id="scrollbar-wrapper">{children}</div>
    {/* </div>
        </div> */}
    {/* {!noFooter && <MainFooter />} */}
    {/* </Col>  */}
    {/* </Row>
    </Container> */}
  </div>
);

// DefaultLayout.propTypes = {
//   /**
//    * Whether to display the navbar, or not.
//    */
//   noNavbar: PropTypes.bool,
//   /**
//    * Whether to display the footer, or not.
//    */
//   noFooter: PropTypes.bool,
// };

// DefaultLayout.defaultProps = {
//   noNavbar: false,
//   noFooter: false,
// };

export default DefaultLayout;
