import React from "react";
import blue from "../../../assets/images/bluebg.jpg";
import orange from "../../../assets/images/orangebg.jpg";
import img7 from "../../../assets/images/play-store.png";
import "./index.scss";

const GrievanceRedressal = () => {
  return (
    <div>
      <div className="agreement">
        <div
          className="con"
          style={{
            backgroundImage: `url(${blue})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "revert",
          }}
        >
          <div style={{ paddingTop: "40px" }} className="col">
            <div className="user">
              <div className="user__heading">
                <h1>GRIEVANCE REDRESSAL</h1>
              </div>
            </div>
          </div>
          <div className="user__content1">
            <p className="p-tag">
              ChattyBao is duty bound to provide fair treatment to our Consumer
              and Consumer grievances.
              <br />
              <br />
              Grievance means any issue related to the product/service which has
              been availed by the consumer from the ChattyBao Platform and
              consumer is seeking resolution for the same.
              <br />
              <br />
              In case of any grievance, objection or complaint on your part with
              respect to the Platform, any issue pertaining to transactions with
              Merchants, including any complaints or enquiry about suspension,
              termination or blocking of your membership or right to use the
              Platform, you should promptly raise such grievance or complaint
              with the designated Grievance Officer at admin@chattybao.com and
              provide the Grievance Officer with all necessary information
              and/or documents to enable the Company/Grievance Officer to try
              and resolve the issue. For each grievance lodged, the Company will
              issue a ticket number for each complaint lodged through which you
              can track the status of the complaint.
              <br />
              <br />
              The Grievance Officer shall attempt to acknowledge your
              grievances/ complaints within 48 (forty- eight) hours of receiving
              it. The Grievance Officer may requisition such information as he
              may require in order to look into the grievances/ complaints for
              resolving the same. The Grievance Officer shall attempt to resolve
              the grievance/ complaint within 1 (one) month of having received
              it. You acknowledge that in the event you fail to provide the
              information sought by the Grievance Officer to look into your
              complaint, the Grievance Officer shall not be able to proceed with
              your grievance/ complaint, and as such, the grievance/ complaint
              may remain unresolved. You hereby consent and authorize the
              Grievance Officer to contact you on the basis of the information
              provided by you, for the purposes of the grievance/ complaint.
            </p>
            <h6 className="header_tag">Grievance officer / Nodal Officer</h6>
            <p className="p-tag">
              In accordance with Information Technology Act 2000 and rules made
              there under and the Consumer Protection (E-Commerce) Rules, 2020,
              the name and contact details of the Grievance Officer are provided
              below:
              <br />
              <br />
              Jatin Khurana
              <br /> Designation: Head, Platform Experience
              <br />
              <br />
              ChattyBao Technologies Pvt Ltd
              <br />
              C-17, Basement, B-3, Guru Nanak Pura,
              <br />
              Laxmi Nagar, New Delhi 110092
              <br />
              <br />
              Contact us: help@chattybao.com
              <br />
              Phone: 7353256777
              <br />
              Time: Mon-Sat (9 am to 6 pm)
              <br />
              <br />
              For more details, please visit Terms of Use
            </p>
          </div>
        </div>
      </div>
      <div
        className="container1"
        style={{
          backgroundImage: `url(${orange})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "revert",
        }}
      >
        <div className="con-2">
          <div className="con-2__content">
            <h1>Have a Business ?</h1>
            <h2>5 minute Sign up</h2>
            <div className="con-2__link">
              <a
                href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                target="_blank"
                rel="noreferrer"
              >
                <img src={img7} alt="" />
              </a>
              <div className="con-2__link-content">
                <a
                  href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Download Now</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrievanceRedressal;
