import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import NewHome from '../pages/newhome';
import PrivacyPlicy from '../pages/privacypolicy';
import UserAgreement from '../pages/useragreement';
import ContactUs from '../pages/contactus';
import Parallax from '../pages/parallax';
import BusinessPage from '../pages/businesspage/BusinessPage';
import Footer from '../pages/footer/index';
import Header from '../pages/header';
import GrievanceRedressal from '../pages/GrievanceRedressal/GrievanceRedressal';
import VipPlan from '../pages/VipPlan/VipPlan';
import CancellationRefunds from '../pages/CancellationRefunds/CancellationRefunds';
import CBForcePrivacy from '../pages/CbForcePrivacy/CBForcePrivacy';
import MerchantAgreementPage from '../pages/merchantagreement/MerchantAgreementPage';
import CBforceagreement from '../pages/cbforceAgreement/CBforceAgreement';
import AboutUs from '../pages/AboutUs/AboutUs';


const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Header />

      <Switch location={location} key={location.pathname}>
        <Route exact path='/' component={NewHome} />
        <Route exact path='/business' component={BusinessPage} />
        <Route exact path='/privacy-policy' component={PrivacyPlicy} />\
        <Route exact path='/user-agreement' component={UserAgreement} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route exact path='/about-us' component={AboutUs} />
        <Route exact path='/parallax' component={Parallax} />
        <Route exact path='/merchantagreement' component={MerchantAgreementPage} />
        <Route exact path='/vip-plan' component={VipPlan} />
        <Route exact path='/Grievance-Redressal' component={GrievanceRedressal} />
        <Route exact path='/CancellationRefundsReturnExchange' component={CancellationRefunds} />
        <Route exact path='/CbForce-PrivacyPolicy' component={CBForcePrivacy} />
        <Route exact path='/merchant-agreement' component={CBforceagreement} />
      </Switch>
      <Footer />

    </AnimatePresence>
  )
}

export default AnimatedRoutes;