import React from "react";
import blue from "../../../assets/images/bluebg.jpg";
import orange from "../../../assets/images/orangebg.jpg";
import img7 from "../../../assets/images/play-store.png";
// import "./index.scss";

const CBforceagreement = () => {
    return (
        <div>
            <div className="agreement">
                <div
                    className="con"
                    style={{
                        backgroundImage: `url(${blue})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "fixed",
                        backgroundPosition: "revert",
                    }}
                >
                    <div style={{ paddingTop: "40px" }} className="col">
                        <div className="user">
                            <div className="user__heading">
                                <h1>SERVICE AGREEMENT FOR PICKUP AND DELIVERY</h1>
                            </div>
                        </div>
                    </div>
                    <div className="user__content1">
                        <p className="p-tag">
                            This document is an electronic record in terms of the Information Technology
                            Act,   2000   (“IT  Act”)   and   rules   made  thereunder,   as   applicable,   and   the
                            amended   provisions   pertaining   to  electronic  records  in  various  statutes,  as
                            amended from time to time. This document is generated by a computer system
                            and does not require any physical signatures.<br /><br />
                            This   Service   Agreement   (“<span style={{ color: "black", fontWeight: "500" }}>Agreement</span>”)   is   a   legal   contract   between   the
                            Service   Provider   and   ChattyBao   Technologies   Private   Limited,   a   Company
                            incorporated under the provisions of the Companies Act, 2013 and having its
                            registered   office   at   C-17,   Basement,   B-3,   Guru   Nanak   Pura,   Laxmi   Nagar,
                            Delhi-110092.   In   this   agreement  wherever  the  context  so  requires  “You”,
                            “Your”, “Yours”, "Service Provider" or “pickup and delivery partner” (“PDP”)
                            shall   mean   any   natural   person   who   has  agreed  to  provide   services  to  the
                            Company referred to as “Chattybao”, “Company”, "We", "Us", "Our" and/or its
                            affiliates, in accordance with the terms of this Service Agreement. ChattyBao
                            and   Service   Provider   shall   collectively   be   referred   to  as  “Parties”  and
                            individually as “Party”.<br /><br />
                            BY   CLICKING   THE   "ACCEPT"   BUTTON   BELOW,   OR   BY   DOWNLOADING,
                            INSTALLING   OR   OTHERWISE   USING   THE   APPLICATION,   YOU   INDICATE
                            THAT YOU HAVE READ AND AGREE TO THE  TERMS  PRESENTED  IN  THIS
                            SERVICE  AGREEMENT.  BY  AGREEING  TO  THE  TERMS,  THE  PARTIES
                            HEREBY ENTER  INTO  A  LEGALLY  ENFORCEABLE  VALID  AGREEMENT  AS
                            PER  THE  INDIAN  CONTRACT  ACT, 1872. IF YOU DO NOT AGREE TO THE
                            TERMS   OF   THIS   AGREEMENT,   DO   NOT   DOWNLOAD,   INSTALL,   OR   USE
                            THE APPLICATION.<br /><br />
                            These terms and conditions are effective from June ...., 2023.<br /><br />
                            <h6 className="header_tag">WHEREAS:</h6>
                            A.You have represented and warranted to ChattyBao that you have a bicycle
                            or validly registered motor vehicle and a valid driving license, and have
                            the   necessary   expertise   and   knowledge   in   order   to   provide   pickup   and
                            delivery services to customers who place orders with merchants listed with
                            ChattyBao Platform.<br /><br />
                            B.On   installing   this   application,   providing   your   requisite   details   to <span style={{ color: "black", fontWeight: "500" }}>ChattyBao</span>, receiving a unique login ID and password, and agreeing to the
                            terms   outlined   in   this   agreement,   you   have   now   consented   to   provide
                            services (hereinafter defined) as PDP, on an exclusive basis, principal to
                            principal   basis,   as   per   the   terms   and   conditions   further   outlined   in   this Agreement.<br /><br />
                            <span style={{ color: "black", fontWeight: "500" }}>NOW,   THEREFORE,</span>   in   consideration   of   the   premises   and   mutual
                            agreements and covenants contained in this Agreement and other good and
                            valuable   consideration   (the   receipt   and   adequacy   of   which   are   hereby
                            mutually acknowledged), each of the Parties hereby agrees as follows:
                        </p>
                        <h6 className="header_tag">1. DEFINITIONS AND INTERPRETATION</h6>
                        <p className="p-tag">
                            <h6 className="header_tag">DEFINITIONS</h6>
                            "<span style={{ color: "black", fontWeight: "500" }}>Agreement</span>” shall mean this Service Agreement for Pick-Up and Delivery
                            and shall include any modifications of this Agreement as may be updated
                            from time to time.<br /><br />
                            "<span style={{ color: "black", fontWeight: "500" }}>Means   of   Communication</span>”   includes   any   communication   issued   by
                            ChattyBao on PDP's registered email ID, registered mobile number, or this
                            ChattyBao application.<br /><br />
                            “<span style={{ color: "black", fontWeight: "500" }}>Services</span>” shall mean the service of pickup and delivery of products; and<br /><br />
                            “<span style={{ color: "black", fontWeight: "500" }}>Term</span>"   shall   mean   the   duration   of   your   association   with   ChattyBao   as   a
                            PDP, which shall be at the sole determination of ChattyBao and which shall
                            be the period during which you shall have an active login ID and password
                            to act as a PDP through this mobile application.<br />
                            <h6 className="header_tag">INTERPRETATION</h6>
                            a) In addition to the above definitions, certain terms may be defined in the
                            Recitals  or  elsewhere  in  this  Agreement,  and  wherever  such  terms  are
                            used in this Agreement, they shall have the meaning so assigned to them.<br /><br />
                            b) All references in this Agreement to statutory provisions shall be statutory
                            provisions for the time being in force and shall be construed as including
                            references to any statutory modifications, consolidation, or re-enactment
                            for the time being in force and all statutory rules, regulations, and orders
                            made pursuant to a statutory provision.<br /><br />
                            c) Words denoting singular shall include the plural and vice versa. Words
                            denoting   any   gender   shalt   include   all   genders   unless   the   context
                            otherwise requires.<br /><br />
                            d) Any reference to "writing" shall include printing, typing, lithography, and
                            other means of reproducing words in permanent visible form.<br /><br />
                            e) The   terms   "include"   and   "including   shall   mean,   "Include   without
                            limitation.<br /><br />
                            f) The headings, sub-headings, titles, subtitles to Clauses, sub-Clauses, and
                            paragraphs are for information only, shall not form part of the operative
                            provisions   of   this   Agreement   or   the   Annexure,   and   shall   be   ignored   in
                            construing the same.
                        </p>
                        <h6 className="header_tag">2. PRICING AND COMPENSATION:</h6>
                        <p className="p-tag">
                            a) The total consideration (“Service Fee”) for the performance of Services
                            by the PDP shall be as per the Payout Scheme available for viewing on
                            this application. ChattyBao shall pay other considerations specific to the
                            city or the zone, including incentives in addition to the Service Fee at its
                            sole discretion, with a right to modify or withdraw at any time without
                            notice. The Payout Scheme is subject to revision by ChattyBao from time
                            to time, and the PDP shall not be entitled to refuse any revision in rates,
                            his/her   only   remedy   being   to   inform   ChattyBao   and   terminate   this
                            Agreement in accordance with Clause 7 below.<br /><br />
                            b) The amounts payable to the PDP shall be calculated based on the data
                            collected by ChattyBao by way of the PDP's activities recorded on the
                            mobile   application.   ChattyBao   shall   keep   a   record   of   the   number   of
                            hours   logged   by   him/her   and   the   number   of   orders   picked   up   and
                            successfully   delivered   by   the   PDP   and   such   records   shall   be   made
                            available to the PDP on request. ChattyBao shall make payments to the
                            PDP on a weekly basis, on the basis of the rates laid down in the Payout
                            Scheme, subject to any adjustments or deductions as may be applicable
                            (available for viewing under the Payout Scheme on this application).<br /><br />
                            c) The PDP may raise any issues with respect to the amounts received by
                            him/her   and/or   any   discrepancies   in   payments,   within   48   (forty-eight)
                            hours   of   receipt   of   such   amounts.   Such   claims   shall   be   resolved   by
                            authorized   ChattyBao   representatives   pursuant   to   due   investigations
                            into the PDP's records and discussions with the PDP with respect to the
                            claim.<br /><br />
                            d) The amounts payable by ChattyBao to the PDP are subject to deduction
                            of tax at source, as per applicable laws.
                        </p>
                        <h6 className="header_tag">3. RELATIONSHIP</h6>
                        <p className="p-tag">
                            a) The   PDP   confirms   that   he/she   has   been   engaged   for   providing   the
                            Services on an Exclusive basis and Principal-to-Principal basis, and the
                            payments   made   under   this   agreement   are   based   on   the   various
                            parameters such as distance, waiting time etc, and number of pick-ups
                            and   deliveries   completed   by   the   PDP   subject   to   any   adjustments   or
                            deductions as laid down under the Payout Scheme.<br /><br />
                            b) The PDP shall have the sole discretion to determine the time or duration
                            for which he/she will log into ChattyBao's mobile application to carry out
                            the Services.<br /><br />
                            c) Nothing in this Agreement shall in any manner or form be construed as
                            amounting   to   a   partnership,   joint   venture,   agency,   employer   and
                            employee   relationship,   or   the   creation   of   any   other   legal   relationship
                            whatsoever between the parties.<br /><br />
                            d) Neither   Party   shall   have   any   authority   to   bind   the   other   for   the
                            performance of any obligations other than as specifically contemplated
                            herein.
                        </p>
                        <h6 className="header_tag">4. STATUTORY REQUIREMENTS</h6>
                        <p className="p-tag">
                            a) Both Parties shall take all such actions as may be required or prudent
                            under the circumstances to enable them to remain in good standing, and
                            to   obtain   and   keep   current   all   Government   licenses,   permits   and
                            approvals, municipal licenses, and other operational licenses which are
                            necessary or advisable for the performance of the respective obligations
                            under this Agreement and each party will indemnify the other party for
                            any claim, action, proceeding, damages in respect of the same.<br /><br />
                            b) The   PDP   shall   comply   with   all   Governmental   and   statutory
                            requirements. The PDP hereby agrees to indemnify and keep ChattyBao
                            indemnified   from   time   to   time   against   any   and   all   losses,   damages,
                            penalties,   actions,   proceedings   etc.,   that   may   be   instituted   by
                            Government officials or any authority in the discharge of their official
                            duties   under   any   law   or   rule   or   regulation   as   may   be   applicable,   on
                            account of any lapse or irregularity caused by the PDP in carrying out
                            his obligations under this Agreement.<br /><br />
                            c) In   case   the   PDP   is   registered   under   GST,   the   same   should   be
                            communicated to ChattyBao.
                        </p>
                        <h6 className="header_tag">5. INTELLECTUAL PROPERTY</h6>
                        <p className="p-tag">
                            a) PDP recognizes that ChattyBao is the registered owner of the word mark
                            “<span style={{ color: "black", fontWeight: "500" }}>ChattyBao</span>” and the logo   including but not limited to its variants
                            and shall not directly or indirectly, attack or assist another in attacking
                            the validity of, or ChattyBao's or its affiliates proprietary rights in the
                            Licensed Marks or any registrations thereof, or file any applications for
                            the registration of the Licensed Marks or any names or logos derived
                            from or confusingly similar to the Licensed Marks, any variation thereof,
                            or   any   translation   or   transliteration   thereof   in   another   language,   in
                            respect   of   any   products/services   and   in   any   territory   throughout   the
                            world.<br /><br />
                            b) The   PDP   shall   not,   by   the   performance   of   any   of   the   Services   or
                            otherwise, acquire any rights in or to use any trademark, service mark,
                            corporate   name,   copyright,   patent   or   trade   secret   or   the   like
                            (collectively   called   "<span style={{ color: "black", fontWeight: "500" }}>IPR</span>”)   developed,   owned,   used   or   adopted   by
                            ChattyBao or any of its affiliates now or in the future, whether or not
                            such IPR is registered.<br /><br />
                            c) During the Term of this Agreement, the PDP may use only such IPR as
                            expressly permitted to be used by ChattyBao, and only in the course of
                            performance   of   the   Services   as   provided   in   this   Agreement   and   as
                            directed by ChattyBao pursuant to this Agreement
                        </p>
                        <h6 className="header_tag">6. INDEMNITY:</h6>
                        <p className="p-tag">
                            a) We   expressly   understood   that   the   PDP   is   an   independent   entity   and
                            ChattyBao has no control or supervision over the PDP with respect to
                            the amount of time for which, and the manner in which he/she carries
                            out his/her obligations under this Agreement.<br /><br />
                            b) It   is   hereby   expressly   stated   that   the   PDP   shall   have   complete
                            responsibility   to   comply   with   all   applicable   laws,   orders,   regulations,
                            and   rules   or   any   governments,   statutory   or   other   authorities   having
                            appropriate   jurisdiction.   In   this   regard,   the   PDP   shall   always   keep
                            Chattybao indemnified and shall hold ChattyBao harmless against any
                            proceedings   or   prosecution   initiated   by   any   statutory   body   or   a   third
                            party and shall bear the costs of any such proceedings or prosecution,
                            including advocates fees and related expenses.<br /><br />
                            c) The POP shall indemnify and hold harmless ChattyBao and its officers,
                            directors,  employees,  and  contractors,  and  shall  on  demand,  promptly
                            reimburse   them,   for   any   and   all   payments   of   money   including   fines,
                            damages,   legal   fees,   and   expenses   by   reason   of   any   claim,   demand,
                            liability, tax, or judicial or administrative investigation or proceeding:<br /><br />
                            i.arising from any act, omission, or obligation of the PDP or anyone
                            hired, employed by, or associated or affiliated with the PDP; or<br /><br />
                            ii.otherwise   with   respect   to   the   PDP's   performance   or   the   Services
                            and not arising from the fault or negligence of ChattyBao.
                        </p>
                        <h6 className="header_tag">7. TERMINATION AND SUSPENSION OF SERVICE:</h6>
                        <p className="p-tag">
                            a) Termination of the relationship between ChattyBao and the PDP may be
                            effected in the following manner:<br /><br />
                            i.In the event of a breach of the terms of this Agreement by the PDP or
                            ChattyBao discovering a breach of any of the obligations or of the
                            Guidelines (available for viewing on this mobile application) by the
                            PDP   or   an   incurable   lapse   in   the   Services   performed   by   the   PDP,
                            ChattyBao   may   immediately   notify   of   the   PDP   of   such   breach   or
                            lapse, and deactivate the login ID provided for the PDP to log into
                            this mobile application. Provided that acts or omissions resulting in
                            situations   including   but   not   limited   to   violation   of   applicable   laws
                            may   be   construed   as   an   incurable   lapse.   ChattyBao   shall   tally   the
                            amounts   payable   to   the   PDP   according   to   the   Payout   Scheme   and
                            complete full and final settlement of all amounts payable within 20
                            (twenty)   days   from   the   date   of   deactivation   of   the   PDP's   account,
                            subject to Clause 7(c)(iii).<br /><br />
                            ii.ChattyBao may, at any time, decide to terminate this Agreement, and
                            shall provide  24 (twenty four) hours’ notice  of such termination to
                            the PDP. The login ID provided to the PDP shall be deactivated at the
                            end   of   24   (twenty   four)   hours   as   mentioned   hereinabove,   and
                            ChattyBao shall complete the full and final settlement of all amounts
                            payable within 20 (twenty) days from the date of deactivation of the
                            PDP's account, subject to Clause 7(c)(iii).<br /><br />
                            iii.The PDP may notify a ChattyBao representative of his/her intention
                            to   terminate   the   Agreement   with   ChattyBao   at   least   5   (five)   days
                            prior to such intended termination. The login ID provided to the PDP
                            shall   be   deactivated   on   the   5   (fifth)   day,   and   ChattyBao   shall
                            complete the full and final settlement of all amounts payable within
                            20 (twenty) days from the date of deactivation of the PDP's account.
                            subject to Clauses 7(c)(iii) and 7(e ).<br /><br />
                            iv.In the event the PDP falls to notify ChattyBao of his/her intention to
                            terminate   the   Agreement   as   specified   under   Clause   7   (a)   (iii)   and
                            uninstall   the   mobile   application,   ChattyBao   shall   not   be   liable   to
                            settle any amounts payable to the PDP.<br /><br />
                            v.ChattyBao also reserves the right to terminate the Agreement where
                            the PDP is not carrying the field bags and/or not weaning the shirts
                            provided by ChattyBao, while performing Services.<br /><br />
                            b) ChattyBao   may,   at   any   point,   contact   the   PDP   by   way   of   Means   of
                            Communication or by directly contacting the PDP to notify him/her of
                            any breach of any term of this Agreement. ChattyBao may, at its sole
                            discretion give the PDP 15 (Fifteen) days to rectify the breach, and in
                            the event the PDP rectifies the breach to the satisfaction of ChattyBao,
                            ChattyBao   may   at   its   option   elect   not   to   terminate   this   Agreement,
                            however,   shall   have   right   to   suspend   the   services   during   the   notice
                            period.<br /><br />
                            c) Upon   termination   of   this   Agreement   in   any   manner,   the   following
                            provisions shall take effect:<br /><br />
                            i.All rights granted to the PDP under or pursuant to this Agreement
                            shall cease, and the PDP shall have no further rights or obligation to
                            perform the Services.<br /><br />
                            ii.Each   Party   shall   immediately   cease   its   activities   concerning   the
                            Services under this Agreement, subject to the PDP picking up and
                            delivering all orders that have been placed by Customers prior to the
                            termination.<br /><br />
                            iii.On ChattyBao instructing the PDP to do so, the PDP shall promptly
                            return all items including but not limited to all sales literature, sales
                            brochures,   samples,   packaging,   invoices,   delivery   records,   waybills
                            and other materials or facilities of any kind furnished or owned by
                            ChattyBao or referring to ChattyBao.<br /><br />
                            iv.ChattyBao shall pay the requisite dues to the PDP as per the Payout
                            Scheme,   subject   to   any   penalties   or   deductions   that   may   be
                            applicable   as   set   out   herein   or   in   the   Payout   Scheme,   for   the
                            Services rendered up to the date of termination of this Agreement,
                            and the PDP shall also settle all outstanding dues, if any, payable to
                            ChattyBao.<br /><br />
                            v.The   clauses   pertaining   to   Intellectual   Property,   Indemnity,
                            Confidentiality,   and   Arbitration   shall   survive   termination   of   this
                            Agreement.<br /><br />
                            d) Subject   to   clause   7   (a)   (iv),   in   the   event   the   PDP   terminates   this
                            Agreement   without   notice,   ChattyBao   shall   be   entitled   to   deduct   the
                            following amounts forthwith (in addition to any other amounts due from
                            the PDP to ChattyBao as determined at the time of tallying the full and
                            final   settlement   in   case   PDP   returns   at   a   later   date   for   settlement   of
                            amounts payable to him) from the amounts Payable to PDP:
                            i.INR   1,600/-   (Indian   Rupees   One   Thousand   Six   Hundred   only)   as   a
                            recovery   of   sourcing   and   onboarding   costs,   in   the   event   the   PDP
                            leaves without notifying ChattyBao of his/her intention to terminate
                            this Agreement within 1 (one) month of his/her appointment.
                            ii.Any amounts applicable as TDS to be withheld by ChattyBao at the
                            time of making payouts; and
                            This   Clause   (d)   shall   apply   if   Chattybao   during   inspection   of   Services
                            concludes that the PDP has ceased to perform his/her obligations and
                            has   failed   to   notify   Chattybao   his/her   intention   to   terminate   this
                            Agreement, or has uninstalled the mobile application without notice to
                            ChattyBao.
                            All the above charges are inclusive of any applicable taxes including but
                            not limited to GST.<br /><br />
                            e) In the event a PDP terminates the Agreement within 1 (one) month from
                            his/her   appointment   in   accordance   with   clause   7(a)(iii)   at   the   time   of
                            calculating the full and final settlement, ChattyBao shall be entitled to
                            withhold the following amounts:
                            i.INR  1,600/-  (Indian  Rupees  One  Thousand  Six  Hundred  only)  as
                            training and onboarding costs;
                            All the above charges are inclusive of any applicable taxes including
                            but not limited to GST.<br /><br />
                            f) Chattybao shall be entitled to make the following deductions from the
                            amounts   Payable   to   the   PDP,   in   the   event   a   PDP   terminates   the
                            Agreement   with   or   without   giving   notice,   such   costs   of   field   bags   t-
                            shirts, or any other marketing collaterals or ChattyBao property that the
                            PDP tails to return.<br /><br />
                            g) ChattyBao shall have the right to suspend the Agreement and thereby
                            the Services till such time ChattyBao deems fit and reasonable, without
                            being   liable   to   pay   any   consideration   to   PDP,   in   the   event   ChattyBao
                            services   in   affected   in   a   city/zone   for   any   reason   whatsoever   not
                            attributable to ChattyBao.
                        </p>
                        <h6 className="header_tag">8. ASSIGNMENT:</h6>
                        <p className="p-tag">
                            The   PDP   shall   not   assign   this   Agreement   in   full   or   in   part   or   any   of   his
                            entitlements, rights, abilities, or obligations arising out of this Agreement to
                            any person or third party without the prior written consent of ChattyBao.
                            Consequently, the PDP shall not permit any third person to use the mobile
                            application   installed   on   his/her   mobile   device,   with   the   login   ID   and
                            password provided specifically to the PDP by ChattyBao.
                        </p>
                        <h6 className="header_tag">9. CONFIDENTIALITY:</h6>
                        <p className="p-tag">
                            a) Each   Party   acknowledges   that   during   the   performance   of   this
                            Agreement,   the   other   Party   may   disclose   certain   confidential
                            information to such Party to further the performance of this Agreement.
                            For the purpose of this Agreement, the term "Confidential Information"
                            means   any   and   all   oral   or   written   information   that   is   not   generally
                            known   and   that   receiving   Party   obtained   in   the   performance   of   its
                            service duties in relation to the disclosing Party. The term "Confidential
                            Information   shall   include,   but   shall   not   be   limited   to,   intellectual
                            Property,   classified   information,   inventions,   discoveries,   know   how,
                            ideas,   computer   programs,   source   codes,   object   codes   designs,
                            algorithms, processes and structures, product information, research and
                            development information, lists of clients, and other information relating
                            thereto,   financial   data   and   information,   business   plans   and   processes,
                            pricing and discount policies of the products or services and any other
                            information that disclosing Party may disclose to receiving Party, or that
                            receiving Party may know by virtue of its position or the circumstances
                            in which it learned it. Confidential Information also includes information
                            obtained   by   the   receiving   Party   in   confidence   from   third,   parties,
                            including, but not limited to, its subcontractors, consultants, or clients
                            and   any   other   information   of   a   private,   confidential,   or   secret   nature
                            concerning the disclosing Party whether or not relating to the business
                            of the disclosing Party.<br /><br />
                            b) Each Party agrees that during the Term of this Agreement, it will:<br /><br />
                            i.not disclose any Confidential Information to any third party without
                            the prior written consent of the disclosing Party;<br /><br />
                            ii.not   reproduce   Confidential   Information   in   any   form   except   as
                            required to perform its obligations under this Agreement;<br /><br />
                            iii.not   publish,   reverse   engineer,   decompile   or   disassemble   any
                            Confidential information disclosed by the disclosing Party;<br /><br />
                            iv.not   directly   or   indirectly   export   or   transmit   any   Confidential
                            Information to any country to which such export or transmission is
                            restricted by regulation or statute in India;<br /><br />
                            v.promptly provide the other Party with written notice of any actual or
                            threatened breach of this Clause.<br /><br />
                            c) The provisions of the above Clause 9(b) shall not apply to:<br /><br />
                            i.disclosure of Confidential information that is or becomes generally
                            available to the public other than as a result of disclosure by or at
                            the direction of a Party in violation of this Agreement or<br /><br />
                            ii.disclosure, after giving proper written notice to the other Party, to
                            the  extent  practicable  under  the  circumstances  and  subject  to  any
                            practicable   arrangements   to   protect   confidentiality   in   the   extent
                            required   under   applicable   Laws,   regulations   or   processes   of   any
                            government   authority   or   in   connection   with   any   judicial   process
                            regarding   any   legal   action,   suit   or   proceeding   arising   out   of   or
                            relating to this Agreement.<br /><br />
                            d) The   receiving  Party   further  agrees  that   upon  expiry   or  termination  of
                            this   Agreement,   it   shall   not   Itself   or   through   any   agent   or   otherwise,
                            sell, license, sub-license, market, distribute or otherwise deal with any
                            of the Confidential information (in whole or in part) except to the extent
                            permitted by the disclosing Party in writing.<br /><br />
                            e) The   Receiving   Party   acknowledges   that   breach   of   this   Clause   9   may
                            result   in   an   irreparable   harm   to   the   disclosing   Party   which   is   not
                            capable   of   being   assigned   a   value   and   which   cannot   be   adequately
                            compensated by the recovery of damages alone and that the disclosing
                            Party shall be entitled to seek any remedy available to it under the Law
                            for the time being in force.<br /><br />
                            f) The PDP shall not issue any press releases or announcements, or any
                            marketing   advertising   or   other   promotional   materials,   related   to   this
                            Agreement or referencing ChattyBao or its trade names, trademarks, or
                            service marks without the prior written approval of ChattyBao.
                        </p>
                        <h6 className="header_tag">10. AMENDMENTS:</h6>
                        <p className="p-tag">
                            This Agreement may be altered amended/ modified at any point of time by
                            ChattyBao, during the Term of the Agreement, and the PDP shall receive a
                            notification   of   such   amendment   by   way   of   a   notification   on   this   mobile
                            application.
                        </p>
                        <h6 className="header_tag">11. ENTIRE AGREEMENT:</h6>
                        <p className="p-tag">
                            a) This Agreement, together with the Terms & Conditions, Payout Scheme
                            and Guidelines document, constitutes the entire agreement between the
                            parties with respect to the subject matter hereof and supersedes all prior
                            documents.<br /><br />
                            b) If  any  provision  of  this  Agreement  is  found  to  be  unenforceable  in  any
                            jurisdiction, the balance of this Agreement shall not be effected by the
                            unenforceable provision and such provision, shall, if feasible, be modified
                            in scope so that it becomes enforceable.
                        </p>
                        <h6 className="header_tag">12. DISPUTE RESOLUTION:</h6>
                        <p className="p-tag">
                            a) This Agreement shall be governed by the laws of India and, subject to
                            Clauses 12(b) and 12(c), the courts at New Delhi shall have jurisdiction
                            over   all   matters   of   dispute   arising   out   of   or   pertaining   to   this
                            Agreement.<br /><br />
                            b) Any dispute, difference, Claim, counter-claim arising out of under or in
                            connection   with   this   Agreement   or   any   other   breach   thereof   shall   be
                            resolved by mutual negotiations by and between the parties.<br /><br />
                            c) In   the   event   Parties   are   unable   to   resolve   the   dispute   or   difference
                            amicably,   either   Party   may   refer   such   dispute   or   difference   to
                            arbitration to be conducted by Sole Arbitrator, who shall be appointed
                            by the Company. The arbitration shall be conducted in accordance with
                            the   Indian   Arbitration   and   Conciliation   Act,   1996   and   the   rules   made
                            thereunder.
                        </p>
                        <h6 className="header_tag">13. RECORDS, INSPECTION, AND RIGHT TO AUDIT</h6>
                        <p className="p-tag">
                            The PDP agrees to disclose to ChattyBao all information with regard to the
                            Services   and   the   activities   performed   by   the   PDP   in   relation   to   this
                            Agreement   and   make   available   all   records,   data   and   information   relating
                            thereto as and when requested by ChattyBao.
                        </p>
                    </div>
                </div>
            </div>
            <div
                className="container1"
                style={{
                    backgroundImage: `url(${orange})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundPosition: "revert",
                }}
            >
                <div className="con-2">
                    <div className="con-2__content">
                        <h1>Have a Business ?</h1>
                        <h2>5 minute Sign up</h2>
                        <div className="con-2__link">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={img7} alt="" />
                            </a>
                            <div className="con-2__link-content">
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.goping.merchant"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <p>Download Now</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CBforceagreement;
