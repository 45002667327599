import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, useHistory, Switch } from "react-router-dom";
import routes from "./../../src/routes";
import withTracker from "./../../src/withTracker";
import "./app.css";
import Routers from "./router";
import OrderTracking from "./pages/ONDC-OrderTracking/OrderTracking";
import ApiService from "./utils/ApiService";
import LoadingSpinner from "./components/loadingSpinner/loadingSpinner";
import LoginPage from "../other-catalog-pages/LoginPage";
import { Constants } from "./utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setState } from "./redux/reduxStore";
import chattyBaoLogo from '../assets/chattybao-logox150.png'

function App() {
	const history = useHistory();
	const [url, setUrl] = useState("");
	const [spinner, setSpinner] = useState(false);
	const [urlcheck, setUrlCheck] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [apiCall, setApiCall] = useState(false);
	const dispatch = useDispatch();
	const { domainNames, subPaths } = Constants;

	useEffect(() => {

		var urlPath = window.location.pathname;
		var pathSegments = urlPath.split('/');
		var domainName = window.location.hostname;
		var fullurl = urlPath.substring(urlPath.indexOf("/") + 1);
		setUrlCheck(fullurl);
		//Google Analytics
		var urlTrack = window.location.href;
		var checkTrack = urlTrack.includes("?utm_source");
		console.log(urlTrack + "urlTrack");
		console.log(checkTrack);
		if (checkTrack != "") {
			var urlTrackAdd = window.location.href;
			var result = urlTrackAdd.slice(1);
			var finalUrl = urlTrackAdd.substring(urlTrackAdd.lastIndexOf("https"));
			window.location.replace(finalUrl);
		}

		//Google Analytics
		console.log("fullurl", fullurl)
		if (fullurl === `${localStorage.getItem("fullurl")}/order-summary` || fullurl === `${localStorage.getItem("fullurl")}/cart`) {
			history.push(`/${localStorage.getItem("fullurl")}`)
			window.location.reload();
		} else if (fullurl === 'order-summary' || fullurl === 'cart') {
			history.push('/')
			window.location.reload();
		} else if (fullurl !== 'login') {
			if (!domainNames.includes(domainName)) {
				localStorage.setItem("fullurl", '');
			} else {
				if (subPaths.some(subPath => fullurl.includes(subPath))) {
					const basePath = fullurl.split('/')[0];
					console.log("basePat", basePath)
					localStorage.setItem("fullurl", basePath);
				} else {
					localStorage.setItem("fullurl", fullurl);
				}
			}
		}


		var merchantName = pathSegments[1];
		console.log("merchantName", merchantName);

		setUrl(merchantName);
		if (!domainNames.includes(domainName)) {
			let newPath = "";
			if (window.location.pathname.includes('/product/')) {
				newPath = window.location.href.split('/product/')[0];
			} else if (window.location.pathname.includes('/past-orders')) {
				newPath = window.location.href.split('/past-orders')[0];
			} else if (window.location.pathname.includes('/cid=')) {
				newPath = window.location.href.split('/cid=')[0];
			} else if (window.location.pathname.includes('/rewards')) {
				newPath = window.location.href.split('/rewards')[0];
			} else {
				console.log("path", pathSegments)
				if (pathSegments.length > 1 && pathSegments[1]) {
					newPath = `https://${domainName}}`
					window.location.href = `https://${domainName}`
				} else {
					newPath = window.location.href;
				}
				const cleanedUrl = removeTrailingSlash(window.location.href);
				newPath = cleanedUrl;
			}
			localStorage.setItem("webUrl", newPath);
			if (localStorage.getItem("fullurl") !== localStorage.getItem("fullurlold")) {
				localStorage.removeItem("cartData");
				localStorage.removeItem("extraInstructions");
				localStorage.setItem("fullurlold", '');
				localStorage.setItem("fullurlcheck", "0");
				window.location.reload()
			}
			const link = document.getElementById('favicon');
			link.href = chattyBaoLogo;
			getMerchantInfoFromDomain(domainName, fullurl);
		} else if (
			fullurl !== "user-agreement" &&
			fullurl !== "privacy-policy" &&
			fullurl !== "merchantagreement" &&
			fullurl !== "business" &&
			fullurl !== "vip-plan" &&
			fullurl !== "Grievance-Redressal" &&
			fullurl !== "CancellationRefundsReturnExchange" &&
			fullurl !== "CbForce-PrivacyPolicy" &&
			fullurl !== "map" &&
			fullurl !== "merchant-agreement" &&
			fullurl !== "tracking" &&
			fullurl !== "login" &&
			fullurl !== "cart" &&
			fullurl !== "product" &&
			fullurl !== "about-us" &&
			fullurl !== "" &&
			fullurl !== null &&
			fullurl !== undefined
		) {
			let newPath = "";
			if (window.location.pathname.includes('/product/')) {
				newPath = window.location.href.split('/product/')[0];
			} else if (window.location.pathname.includes('/past-orders')) {
				newPath = window.location.href.split('/past-orders')[0];
			} else if (window.location.pathname.includes('/cid=')) {
				newPath = window.location.href.split('/cid=')[0];
			} else if (window.location.pathname.includes('/rewards')) {
				newPath = window.location.href.split('/rewards')[0];
			} else {
				console.log("path", pathSegments)
				if (pathSegments.length > 2) {
					newPath = `https://${domainName}/${pathSegments[1]}`
					window.location.href = `https://${domainName}/${pathSegments[1]}`
				} else {
					newPath = window.location.href;
				}
			}
			localStorage.setItem("webUrl", newPath);
			if (localStorage.getItem("fullurl") !== localStorage.getItem("fullurlold")) {
				localStorage.removeItem("cartData");
				localStorage.removeItem("extraInstructions");
				if (subPaths.some(subPath => fullurl.includes(subPath))) {
					const basePath = fullurl.split('/')[0];
					localStorage.setItem("fullurlold", basePath);
				} else {
					localStorage.setItem("fullurlold", fullurl);
				}
				localStorage.setItem("fullurlcheck", "0");
				window.location.reload()
			}
			getMerchant(merchantName, fullurl);
			// getMerchantInfoFromDomain(merchantName, fullurl);
		}
	}, []);

	const removeTrailingSlash = (url) => {
		return url.endsWith('/') ? url.slice(0, -1) : url;
	};

	const getMerchant = async (merchantName, fullurl, value) => {
		setIsLoading(true);
		let token = null;
		if (localStorage.getItem('sessionId')) {
			token = localStorage.getItem('sessionId');
		}
		const data = {
			merchantId: merchantName,
			ulatitude: '16.43',
			ulongitude: '74.59'
		}
		try {
			const result = await ApiService.getMerchantDataForCatalogue(data, token);
			console.log("RESSN", result)
			if (result?.data?.code == 0) {
				document.title = result.data.merchantDetails.shopname;
				if (result.data.merchantDetails.subscription_flag <= 1) {
					window.location.href = 'https://refer.chattybao.com/app'
				} else {
					localStorage.removeItem("fromContact")
					localStorage.setItem("merchantdetails", JSON.stringify(result.data));
					setApiCall(true);
					if (value === 'multiple') {
						const multiStoreCart = JSON.parse(localStorage.getItem("multi-store-cart"));
						if (multiStoreCart) {
							const storeCart = multiStoreCart.find(data => data.merchantId === result?.data?.merchantDetails?.merchantid);
							if (storeCart && storeCart?.cartData.length !== 0) {
								localStorage.setItem("cartData", JSON.stringify(storeCart?.cartData))
							} else {
								localStorage.removeItem("cartData");
							}
						}
					}
				}
			} else {
				setUrl("");
				if (
					fullurl == "user-agreement" ||
					fullurl == "privacy-policy" ||
					fullurl == "merchantagreement" ||
					fullurl == "business" ||
					fullurl == "vip-plan" ||
					fullurl == "Grievance-Redressal" ||
					fullurl == "CancellationRefundsReturnExchange" ||
					fullurl == "CbForce-PrivacyPolicy" ||
					fullurl == "map" ||
					fullurl == "merchant-agreement" ||
					fullurl == "tracking" ||
					fullurl == "login" ||
					fullurl == "cart" ||
					fullurl == "about-us"
				) {
					setUrl(fullurl);
					localStorage.setItem("fullurlold", fullurl);
					localStorage.setItem("fullurl", fullurl);
					console.log("FulURL===> ", fullurl)
				} else {
					fullurl = "";
					setUrl('/');
					history.push('/');
					window.location.reload();
				}
			}
		} catch (error) {
			alert(error.message)
		} finally {
			setIsLoading(false);
		}
	};

	const getMerchantInfoFromDomain = async (domainName, fullurl) => {
		setIsLoading(true);
		const data = {
			domain: domainName,
			// domain: 'ramjeevan.xyz'
		}
		try {
			const result = await ApiService.getMerchantInfoFromDomain(data);
			console.log("RESSN", result)
			if (result?.data?.code == 0) {
				setUrl("newDomain")
				setUrlCheck("newDomain");
				localStorage.removeItem("fromContact")
				const selectedStoreId = localStorage.getItem("selected-store-id")
				if (result?.data?.data?.length === 1) {
					if (selectedStoreId !== result?.data?.data[0]?.merchantid) {
						localStorage.removeItem("extraInstructions");
						localStorage.removeItem("selected-store-id");
					}
					getMerchant(result?.data?.data[0]?.merchantid, fullurl)
				} else {
					const storeList = result?.data?.data;
					const index = storeList.findIndex(item => item?.parent_merchant_id === item?.merchantid);
					if (index > 0) {
						localStorage.setItem("merchantdetails", JSON.stringify({ merchantDetails: storeList[index] }));
						// Remove the object from its current position
						const [item] = storeList.splice(index, 1);

						// Add the object at the beginning of the array
						storeList.unshift(item);
					} else {
						localStorage.setItem("merchantdetails", JSON.stringify({ merchantDetails: storeList[0] }));
					}
					dispatch(setState({ key: "multipleStores", value: storeList }));
					if (selectedStoreId) {
						getMerchant(selectedStoreId, fullurl, 'multiple');
						dispatch(setState({ key: "merchantSelected", value: true }));
					} else {
						setApiCall(true);
					}
				}
			} else {
				setUrl('/');
				history.push('/');
			}
		} catch (error) {
			alert(error.message)
		} finally {
			setIsLoading(false);
		}
	};

	console.log("url", url);


	return (
		<div>
			{isLoading ? (
				<LoadingSpinner />
			) : (
				<div className="main">
					{(
						(
							url !== "" &&
							url !== "user-agreement" &&
							url !== "privacy-policy" &&
							url !== "merchantagreement" &&
							url !== "business" &&
							url !== "vip-plan" &&
							url !== "Grievance-Redressal" &&
							url !== "CancellationRefundsReturnExchange" &&
							url !== "CbForce-PrivacyPolicy" &&
							url !== "merchant-agreement" &&
							url !== "about-us"
						) || (url === 'newDomain')) && (
							<>
								{url === "tracking" ? (
									<Router basename={process.env.REACT_APP_BASENAME || ""}>
										<Route
											path={"/tracking"}
											component={OrderTracking}
										/>
									</Router>
								) : url === 'login' ? (
									<Router basename={process.env.REACT_APP_BASENAME || ""}>
										<Route
											path={"/login"}
											component={LoginPage}
										/>
									</Router>
								) : (
									<>
										{apiCall && (
											<Router basename={process.env.REACT_APP_BASENAME || ""}>
												<div>
													<Switch>
														{routes.map((route, index) => {
															console.log("route", route)
															return (
																<Route
																	key={index}
																	path={route.path}
																	exact={route.exact}
																	component={withTracker((props) => {
																		return (
																			<route.layout {...props}>
																				<route.component {...props} />
																			</route.layout>
																		);
																	})}
																/>
															);
														})}
													</Switch>
												</div>
											</Router>
										)}
									</>

								)}
							</>


						)}
					{urlcheck == "" && <Routers />}
					{url === "user-agreement" && <Routers />}
					{url === "privacy-policy" && <Routers />}
					{url === "merchantagreement" && <Routers />}
					{url === "business" && <Routers />}
					{url === "about-us" && <Routers />}
					{url === "vip-plan" && <Routers />}
					{url === "Grievance-Redressal" && <Routers />}
					{url === "CancellationRefundsReturnExchange" && <Routers />}
					{url === "CbForce-PrivacyPolicy" && <Routers />}
					{url === "merchant-agreement" && <Routers />}
				</div>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={spinner}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}

export default App;
