import { GoogleMap, Marker, OverlayView, useLoadScript } from '@react-google-maps/api';
import { useState, useEffect, useCallback } from 'react';
import { useRef } from 'react';
import './Map.css'
import { useHistory } from "react-router-dom";
import cb_logo from '../assets/cb_logo.png';
import blue_dot from '../assets/blue_dot.png';
import google_logo from '../assets/powered-by-google.png';
import { faSearch, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
} from "@mui/material";
import current_location from '../assets/current_location.jpg';
import useStyles from '../app/components/Card/styles';
import ApiService from '../app/utils/ApiService';
import { APIConfig } from '../app/utils/apiConfig';
const libraries = ["places"];

function MapContainer() {
  const [currLocation, setCurrLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState({});
  const [notfirst, setNotfirst] = useState(false);
  const [overlayPosition, setOverlayPosition] = useState(mapCenter);
  const [address, setAddress] = useState('');
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: APIConfig.gmapApiKey,
    libraries,
  });
  const [showAddressSearch, setShowAddressSearch] = useState(false);

  const mapRef = useRef(null);
  const overlayRef = useRef(null);
  const searchInputRef = useRef(null);
  const history = useHistory();

  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState('');
  const [query, setQuery] = useState('')
  const classes = useStyles();

  useEffect(() => {
    if (history.action === "PUSH") {
      setShowAddressSearch(true);
    }
  }, []);

  useEffect(() => {
    if (query.length >= 5) {
      const autocomplete = new window.google.maps.places.AutocompleteService();
      const options = {
        input: query,
        componentRestrictions: { country: 'in' }
      };
      autocomplete.getPlacePredictions(options, (predictions, status) => {
        if (status === 'OK') {
          setPlaces(predictions);
        }
      });
    }

  }, [query]);

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          handleSuccess();
        } else if (result.state === 'prompt') {
          // User hasn't made a decision yet
          // alert('Location access prompt will be shown.');
        } else if (result.state === 'denied') {
          // User denied location access without seeing the prompt
          // alert('Location access was previously denied without a prompt.');
        }
      });
    } else if (navigator.geolocation) {
      // For older browsers that don't support navigator.permissions
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      // alert('Geolocation is not supported by this browser.');
    }
  }, []);

  const handleSuccess = (position) => {
    // User granted location access
    const { latitude, longitude } = position.coords;
    alert('Granted');
    console.log('Latitude:', latitude);
    console.log('Longitude:', longitude);
  };

  const handleError = (error) => {
    // User denied location access or an error occurred
    switch (error.code) {
      case error.PERMISSION_DENIED:
        // alert('User denied the request for Geolocation.');
        break;
      case error.POSITION_UNAVAILABLE:
        // alert('Location information is unavailable.');
        break;
      case error.TIMEOUT:
        // alert('The request to get user location timed out.');
        break;
      case error.UNKNOWN_ERROR:
        // alert('An unknown error occurred.');
        break;
      default:
      // alert('An unknown error occurred.');
    }
  };

  const handleInputChange = () => {
    // if (searchInputRef.current.value.length >= 5) {
    setQuery(searchInputRef.current.value);
    // }
  };

  const handlePlaceSelect = place => {
    setSelectedPlace(place);
    setQuery(place.description);
    // Do something with the selected place

    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    placesService.getDetails({ placeId: place.place_id }, (details, status) => {
      if (status === 'OK') {
        console.log('Selected place:', details.name, details.geometry.location.lat(), details.geometry.location.lng());
        // setSearchLat(details.geometry.location.lat())
        // setSearchLng(details.geometry.location.lng())
        setMapCenter({ lat: details.geometry.location.lat(), lng: details.geometry.location.lng() });
        setOverlayPosition({ lat: details.geometry.location.lat(), lng: details.geometry.location.lng() });
        setShowAddressSearch(false);
        getAddress(details.geometry.location.lat(), details.geometry.location.lng());
        // Do something with the selected place details
      }
    });
  };


  const handleOverlayChange = () => {
    // console.log("newLatLng==>", latLng)
    const newCenter = {
      lat: mapRef.current.center.lat(),
      lng: mapRef.current.center.lng()
    };
    setOverlayPosition(newCenter);
    // console.log("ref",mapRef.current.center.ln)
    getAddress(newCenter.lat, newCenter.lng);
  };

  const handleCenterChange = () => {
    if (notfirst) {
      const newCenter = {
        lat: mapRef.current.center.lat(),
        lng: mapRef.current.center.lng()
      };
      setOverlayPosition(newCenter);
      // getAddress(newCenter.lat, newCenter.lng);
    } else {
      setNotfirst(true);
    }

  };



  const mapOptions = {
    mapTypeControl: false,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    myLocationButton: true,
    ClickableIcons: false,
    gestureHandling: 'greedy',
    scaleControl: false,
    keyboardShortcuts: false,
  };

  const getAddress = async (myLat, myLon) => {
    const token = localStorage.getItem('sessionId')
    console.log("LATLNG==>", myLat, myLon)
    const data = {
      latitude: myLat,
      longitude: myLon
    }
    try {
      const result = await ApiService.getUserAddressFromLatLong(data, token);
      console.log("RES", result)
      if (result?.data?.code == 0) {
        const addr = {};
        addr.lat = myLat;
        addr.lng = myLon;
        addr.city = result.data.data?.city;
        addr.pincode = result.data.data?.pincode;
        addr.address = result.data.data?.address;
        setAddress(addr)
      } else {
        console.log(result.message)
      }
    } catch (error) {
      console.log(error)
    } finally { }
    // await ApiService.gMapsApi(myLat, myLon)
    //   .then(response => response.json())
    //   .then(async responseJson => {
    //     let addr = responseJson.results[0];
    //     const regex = /[A-Z0-9]{4}\+[A-Z0-9]{1,4}/
    //     const hasGeoCode = addr.formatted_address.search(regex) !== -1
    //     if (hasGeoCode) {
    //       // console.log('Before removal:', addr.formatted_address);
    //       addr.formatted_address = addr.formatted_address.substring(addr.formatted_address.indexOf(' ') + 1)
    //       // console.log('Before removal:', addr.formatted_address);
    //       setAddress(addr)
    //     } else {
    //       setAddress(addr);
    //     }
    //   })
  }

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrLocation(currentLocation);
          setOverlayPosition(currentLocation);
          setMapCenter(currentLocation);
          console.log(currentLocation)
          getAddress(currentLocation.lat, currentLocation.lng)
        },
        () => null
      );
    }
  }



  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
  }, []);

  if (loadError) return <div>Map cannot be loaded right now, sorry.</div>;
  if (!isLoaded) return <div>Loading...</div>;


  const getPixelPositionOffset = (width, height) => {
    return {
      x: -width / 2,
      y: -height,
    };
  }



  return (
    // <LoadScript
    //   googleMapsApiKey='AIzaSyDgzOSXP40P4tXXs1a2uUQ4dblD9pXKdEY'

    // >
    <div style={{ height: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
      <div className='map_container'>
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={{ height: '100%', width: '100%' }}
          zoom={17}
          center={mapCenter}
          options={mapOptions}
          ref={mapRef}
          // onDrag={handleOverlayChange}
          onDragEnd={handleOverlayChange}
          onCenterChanged={handleCenterChange}
        >
          {console.log("mapcenter==>", address)}
          <div className='map_bottom_address_container'>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '20px', paddingRight: '40px', marginTop: '5px', alignItems: 'center' }}>
              <p className="selectdelivery_text">Select Delivery Location</p>
              <p className="change_text" style={{ color: '#0375b1' }} onClick={() => { setShowAddressSearch(true); setQuery(''); setPlaces([]) }}>CHANGE</p>
            </div>
            <p className='formattedaddress_text'>{address.address}</p>
            <center>
              <button className='confirm_btn' style={{ width: '90%', height: '40px', borderStyle: 'none', border: 'none', color: 'white', backgroundColor: '#0375b1', borderRadius: '5px', marginTop: '10px', marginBottom: '10px' }} onClick={address === '' ? null : () => history.push({ pathname: '/address', state: { address: address } })}>Confirm Location</button>
            </center>
          </div>
          <img onClick={getCurrentLocation} src={current_location} className="current_location_btn" style={{ width: '30px', position: 'absolute', top: '1%', right: '1%' }} alt="NA" />
          <OverlayView
            position={overlayPosition}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
            ref={overlayRef}
          >
            <>
              <div style={{ backgroundColor: '#0375B0', padding: '10px', paddingRight: '10px', width: '100%', borderRadius: '10px', position: 'relative' }}>
                <p className='map_pin_textfirst'>Order will be delivered here</p>
                <p className='map_pin_textsecond'>Place pin accurately on map</p>
                <div className="marker" style={{ position: 'absolute', bottom: '-7px', left: '50%', transform: 'translateX(-50%)', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderTop: '10px solid #0375B0' }} />
              </div>
            </>

          </OverlayView>
          <Marker position={currLocation} icon={{
            url: blue_dot,
            scaledSize: new window.google.maps.Size(40, 40),
            anchor: { x: 20, y: 10 }
          }} />
        </GoogleMap>

        <Dialog
          classes={{
            paper: classes.bigscreens,
          }}
          open={showAddressSearch}
          fullScreen
          PaperProps={{ sx: { paddingLeft: '10px', paddingRight: '10px' } }}
        >
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FontAwesomeIcon className='start-element' icon={faChevronLeft} style={{ color: '#0375b1', height: '30px' }} onClick={() => setShowAddressSearch(false)} />
              <img className='center-element' src={cb_logo} style={{ height: '60px' }} alt="logo" />
            </div>
            <div>
              <p className='bold_head font_size'>Please provide address</p>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ position: 'relative' }}>
                  <input
                    className='gsearch_input'
                    placeholder='Search for your area or apartment'
                    ref={searchInputRef}
                    type="text"
                    style={{ width: '100%' }}
                    // value={query}
                    onChange={handleInputChange}
                  />
                  <FontAwesomeIcon className='search_icon_address' icon={faSearch} style={{ color: '#0375b1', height: '20px' }} />
                </div>
                {query === '' && (
                  <img src={google_logo} style={{ height: '16px', marginTop: '5px', alignSelf: 'flex-end' }} alt="logo" />
                )}
              </div>
              {(query !== '' && query.length >= 5) && (
                <ul style={{ paddingLeft: 0 }}>
                  {places.map(place => (
                    <li className='search_list' key={place.place_id} onClick={() => handlePlaceSelect(place)}>
                      {place.description}
                    </li>
                  ))}
                </ul>
              )}

            </div>

          </div>
        </Dialog>
      </div>

    </div >

    // </LoadScript>
  );
}


export default MapContainer;
